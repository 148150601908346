[data-theme='bridg'] {
  // The color names below are based on the color names supplied by Anchr
  --color-primary: #022b3b;
  --color-primary-hover: #044862; // Picked ourselfs
  --color-secondary: #69dbc6;
  --color-secondary-hover: #19b396; // Picked ourselfs
  --color-tertiary: #fffff6;
  --color-accent-100: #34817f;
  --color-accent-100-hover: #449b94;
  --color-accent-90: #449b94;
  --color-accent-25: #ccdfdf;
  --color-accent-10: #ebf3f3;
  --color-complementary-100: #ab3c0d;
  --color-complementary-100-hover: #b45026;
  --color-complementary-90: #b45026;
  --color-complementary-25: #ecd3c8;
  --color-complementary-25-hover: #f8eee9;
  --color-complementary-8: #f8eee9;

  /* Background colors,
    use backgrounds to give elements a background color to provide contrast,
    to differentiate importancy or to convey meaning
  */
  --color-bg-default: var(--color-gray-100);
  --color-bg-alternative: var(--color-white);
  --color-bg-primary-brand: var(--color-primary);
  --color-bg-primary-brand-hover: var(--color-primary-hover);
  --color-bg-primary-cta: var(--color-complementary-100);
  --color-bg-primary-cta-hover: var(--color-complementary-90);
  --color-bg-secondary-cta: var(--color-accent-100);
  --color-bg-secondary-cta-hover: var(--color-accent-100-hover);
  --color-bg-tertiary-cta: transparent;
  --color-bg-tertiary-cta-hover: transparent;
  --color-bg-quaternary-cta: var(--color-accent-100);
  --color-bg-quaternary-cta-hover: var(--color-accent-100-hover);
  --color-bg-quinary-cta: var(--color-complementary-25);
  --color-bg-quinary-cta-hover: var(--color-complementary-25-hover);
  --color-bg-senary-cta: var(--color-white);
  --color-bg-senary-cta-hover: var(--color-white);
  --color-bg-menu-toggle: var(--color-secondary);
  --color-bg-menu-toggle-hover: var(--color-secondary-hover);
  --color-bg-filter-cta: transparent;
  --color-bg-filter-cta-hover: var(--color-accent-100-hover);
  --color-bg-disabled-cta: var(--color-gray-200);
  --color-bg-contact-section-primary: var(--color-primary);
  --color-bg-contact-section-accent: var(--color-secondary);
  --color-bg-top-footer: var(--color-accent-25);
  --color-bg-bottom-footer: var(--color-primary);

  // Header
  --header-logo-width: 9.375rem;

  // Footer
  --footer-logo-width: 9.375rem;

  /* Foreground colors,
    Use foregrounds to color text and icons.
    Most foregrounds should be paired with their respective bg.{name} equivalent.
  */
  --color-fg-on-default: var(--color-gray-500);
  --color-fg-on-alternative: var(--color-primary);
  --color-fg-on-primary-brand: var(--color-white);
  --color-fg-on-primary-brand-hover: var(--color-white);
  --color-fg-on-primary-cta: var(--color-white);
  --color-fg-on-secondary-cta: var(--color-white);
  --color-fg-on-tertiary-cta: var(--color-primary);
  --color-fg-on-quaternary-cta: var(--color-white);
  --color-fg-on-quinary-cta: var(--color-complementary-100);
  --color-fg-on-senary-cta: var(--color-primary);
  --color-fg-on-senary-cta-hover: var(--color-primary-hover);
  --color-fg-on-disabled-cta: var(--color-white);
  --color-fg-on-text-cta: var(--color-primary);
  --color-fg-on-text-cta-hover: var(--color-primary-hover);
  --color-fg-on-menu-toggle: var(--color-primary);
  --color-fg-on-menu-toggle-hover: var(--color-primary-hover);
  --color-fg-on-filter-cta: var(--color-accent-100);
  --color-fg-on-filter-cta-hover: var(--color-white);
  --color-fg-on-header-nav: var(--color-primary);
  --color-fg-on-header-nav-hover: var(--color-primary-hover);
  --color-fg-on-contact-section-primary: var(--color-white);
  --color-fg-on-contact-section-accent: var(--color-primary);
  --color-fg-on-top-footer: var(--color-primary);
  --color-fg-on-top-footer-hover: var(--color-accent-90);
  --color-fg-on-bottom-footer: var(--color-white);
  --color-fg-on-bottom-footer-hover: var(--color-accent-90);

  /* Headers colors */
  --color-headings-fg-on-default: var(--color-primary);

  /* Miscellaneous */
  --color-link-elements: var(--color-primary);
  --color-header-logo: var(--color-primary);
  --color-header-logo-hover: var(--color-primary-hover);
  --color-header-logo-accent: var(--color-secondary);
  --color-logo-menu-open: var(--color-secondary);
  --color-logo-menu-open-hover: var(--color-secondary);
  --color-logo-powered-by-menu-open: var(--color-white);
  --color-logo-powered-by-menu-open-hover: var(--color-white);
  --color-logo-accent-menu-open: var(--color-primary-hover);
  --color-logo-accent-menu-open-hover: var(--color-secondary);
  --color-footer-logo: var(--color-primary);
  --color-footer-logo-hover: var(--color-primary-hover);
  --color-quote-text: var(--color-primary);
  --color-quote-mark: var(--color-secondary);
}
