/* stylelint-disable selector-class-pattern */

.u-visually-hidden {
  @include visually-hidden;
}

.u-visually-hidden\@until-md {
  @include until-breakpoint(md) {
    @include visually-hidden;
  }
}

.u-visually-hidden\@from-md {
  @include from-breakpoint(md) {
    @include visually-hidden;
  }
}

.u-visually-hidden--focusable:focus {
  position: relative !important; // IE
  position: unset !important;
  margin: auto !important;
  padding: auto !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  white-space: normal !important;
  clip: auto !important;
  clip-path: none !important;
}
