@import 'app.scss';
/**
 * Button
 */

@keyframes pending-spinner {
  to {
    transform: rotate(1turn);
  }
}

/**
 * ======================================
 * Block
 * ======================================
 */
.button {
  @include button;

  .label {
    line-height: 1.7;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.label {
  display: flex;
}

.iconContainer {
  transition: transform var(--transition-duration-base)
    var(--transition-timing-base);
  margin-left: spacing(2);
  text-decoration: none;

  &:first-child {
    margin-right: spacing(2);
    margin-left: 0;
  }
}

.icon {
  width: 1rem;
  height: 1rem;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.hasIcon:not(.hideLabel) {
  padding-right: spacing(5);
}

.hasIconLeft:not(.hideLabel) {
  padding-left: spacing(5);
}

.button.hideLabel {
  justify-content: center;
  box-shadow: none;

  .label {
    @include visually-hidden;
  }

  .iconContainer {
    margin-left: 0;
  }
}

.button.hideLabel,
.button.noAnimation {
  &:hover,
  &:focus {
    .iconContainer {
      transform: none;
    }
  }
}

.button.secondary {
  --color: var(--color-fg-on-secondary-cta);
  --color-hover: var(--color-fg-on-secondary-cta);
  --bg-color: var(--color-bg-secondary-cta);
  --bg-color-hover: var(--color-bg-secondary-cta-hover);
}

.button.tertiary {
  --color: var(--color-fg-on-tertiary-cta);
  --color-hover: var(--color-bg-secondary-cta-hover);
  --bg-color: transparant;
  --bg-color-hover: transparant;

  border: 1px solid var(--color-bg-secondary-cta);

  &:hover,
  &:focus-visible {
    border-color: var(--color-bg-secondary-cta-hover);
  }
}

.button.quaternary {
  --color: var(--color-fg-on-quaternary-cta);
  --color-hover: var(--color-fg-on-quaternary-cta);
  --bg-color: var(--color-bg-quaternary-cta);
  --bg-color-hover: var(--color-bg-quaternary-cta-hover);
}

.button.quinary {
  --color: var(--color-fg-on-quinary-cta);
  --color-hover: var(--color-fg-on-quinary-cta);
  --bg-color: var(--color-bg-quinary-cta);
  --bg-color-hover: var(--color-bg-quinary-cta-hover);
}

.button.senary {
  --color: var(--color-fg-on-senary-cta);
  --color-hover: var(--color-fg-on-senary-cta-hover);
  --bg-color: var(--color-bg-senary-cta);
  --bg-color-hover: var(--color-bg-senary-cta-hover);
}

.button.outlined {
  --color: var(--color-white);
  --color-hover: var(--color-bg-secondary-cta);
  --bg-color: var(--color-bg-secondary-cta);
  --bg-color-hover: var(--color-white);
  --border-color: var(--color-white);
  --border-color-hover: var(--color-white);
}

.button.text {
  --color: var(--color-fg-on-text-cta);
  --color-hover: var(--color-fg-on-text-cta-hover);
  --bg-color: transparent;
  --bg-color-hover: transparent;
  --border-color: transparent;
  --border-color-hover: transparent;

  padding: 0;
  text-decoration: underline;
  text-underline-offset: spacing(2);

  .label {
    text-align: left;
  }
}

.button.list {
  --bg-color: transparent;
  --bg-color-hover: transparent;
  --border-color: transparent;
  --border-color-hover: transparent;
  --color: (--color-bg-primary-brand);
  --color-hover: var(--color-text-base);

  border-bottom: 1px solid var(--color-border-light);
  border-radius: 0;
  padding-right: 0;
  padding-left: 0;
  width: 100%;

  &:hover {
    border-bottom-color: var(--color-gray-900);

    .iconContainer {
      background-color: var(--color-bg-primary-brand-hover);
    }
  }

  .inner {
    justify-content: space-between;
    width: 100%;
  }

  .label {
    text-align: left;
  }

  .iconContainer {
    border-radius: 50%;
    background-color: var(--color-bg-primary-brand);
    padding: spacing(1);
    color: var(--color-fg-on-primary-brand);
  }
}

.button.minimal {
  --color: inherit;
  --color-hover: inherit;
  --border-color: transparent;
  --border-color-hover: transparent;
  --bg-color: transparent;
  --bg-color-hover: transparent;

  display: inline-block;
  padding: 0;
  min-height: auto;
  font-weight: inherit;

  &:hover,
  &:focus-visible {
    .label {
      text-decoration: underline;
    }
  }

  .inner {
    display: inline-block;
    text-align: inherit;
  }

  .label {
    display: inline-block;
    line-height: inherit;
    font-family: inherit;
    font-weight: inherit;
  }
}

.button.sm {
  padding: 0.625rem spacing(6);

  .label {
    font-family: var(--font-family-base);
    font-weight: 400;
  }
}

.loading {
  .svgIcon {
    height: 1em; // prevents button to resize when spinner is shown
    animation: pending-spinner 750ms linear infinite;
  }
}

.stretch {
  width: 100%;
  max-width: 20rem;
}
