/*  Visually-hidden
    Hides an element visually while still allowing the content to be accessible
    to assistive technology, e.g. screen readers.
    Usage: @include visually-hidden();
    ========================================================================= */
@mixin visually-hidden {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  block-size: 1px !important;
  inline-size: 1px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
}
