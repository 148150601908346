@import 'app.scss';
.footer {
  margin-top: auto;
  background-color: var(--color-bg-top-footer);
  padding-top: spacing(12);
  color: var(--color-fg-on-top-footer);

  @include from-breakpoint(md) {
    padding-top: 0;
  }
}

.container {
  padding-bottom: spacing(10);

  @include until-breakpoint(md) {
    row-gap: spacing(12);
  }

  @include from-breakpoint(md) {
    padding-top: spacing(20);
    padding-bottom: spacing(20);
  }
}

.descriptionColumn,
.firstColumn,
.secondColumn,
.lastColumn {
  grid-column: 1 / -1;
}

.descriptionColumn {
  width: max-content;

  @include from-breakpoint(md) {
    padding-bottom: spacing(14);
  }
}

.firstColumn {
  grid-column: 1 / span 4;

  @include from-breakpoint(lg) {
    grid-column: 1 / span 6;
  }
}

.secondColumn {
  @include from-breakpoint(md) {
    grid-column: 5 / span 4;
  }

  @include from-breakpoint(lg) {
    grid-column: 7 / span 3;
  }
}

.lastColumn {
  @include from-breakpoint(md) {
    grid-column: 9 / span 4;
  }

  @include from-breakpoint(lg) {
    grid-column: 10 / -1;
  }
}

.logo {
  color: var(--color-footer-logo);

  &:hover,
  &:focus-visible {
    color: var(--color-footer-logo-hover);
  }
}

.logoSvg {
  width: var(--footer-logo-width);
  height: auto;
}

.title {
  @include typography-paragraph-xs;

  opacity: 0.7;
  margin-bottom: spacing(4);
  color: var(--color-fg-on-top-footer);
}

.links {
  display: grid;
  gap: spacing(4);
  line-height: 1.25;
}

.link {
  text-decoration: none;
  color: var(--color-fg-on-top-footer);

  &:hover,
  &:focus-visible {
    color: var(--color-fg-on-top-footer-hover);
  }
}

.socialsContainer {
  display: flex;
  gap: spacing(2);
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: spacing(4);
  border-radius: 50%;
  background-color: var(--color-bg-primary-brand);
  padding: spacing(2);
  width: spacing(12);
  height: spacing(12);
  color: var(--color-fg-on-primary-brand);

  &:hover,
  &:focus-visible {
    background-color: var(--color-bg-primary-brand-hover);
    color: var(--color-fg-on-primary-brand-hover);
  }
}

.socialLink {
  color: var(--color-fg-on-top-footer);

  &:hover,
  &:focus-visible {
    color: var(--color-fg-on-top-footer-hover);
  }
}

.bottomWrapper {
  background-color: var(--color-bg-bottom-footer);
}

.bottomContainer {
  grid-column: 1 / -1;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  color: var(--color-fg-on-bottom-footer);

  .link {
    color: var(--color-fg-on-bottom-footer);

    &:hover,
    &:focus-visible {
      color: var(--color-fg-on-bottom-footer-hover);
    }
  }

  @include from-breakpoint(md) {
    padding-top: spacing(6);
    padding-bottom: spacing(6);
  }
}

.bottomContainer,
.disclaimerLinks {
  @include typography-paragraph-xs;

  display: flex;
  flex-direction: column;
  gap: spacing(2);

  @include from-breakpoint(md) {
    flex-flow: row wrap;
    gap: spacing(12);
    align-items: center;
  }
}
