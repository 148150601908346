/* stylelint-disable selector-class-pattern */

.u-container-width {
  @include container-width;
}

.u-container-text {
  --max-width: var(--max-text-width);
}

.u-container-grid {
  @include container-grid;
}

.u-container {
  @include container;
}
