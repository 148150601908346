/* stylelint-disable selector-class-pattern */

/**
 * Grid layout utility
 */

.u-grid {
  --grid-spacing: #{spacing(10)};

  display: grid;
  grid-gap: var(--grid-spacing);
}

.u-grid--2\@sm {
  @include from-breakpoint(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.u-grid--2\@md {
  @include from-breakpoint(md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.u-grid--2\@lg {
  @include from-breakpoint(lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.u-grid--3\@lg {
  @include from-breakpoint(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.u-grid--3\@xl {
  @include from-breakpoint(xl) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/**
 * Utility classes for grid spacing.
 */

.u-grid--card-grid {
  --grid-spacing: #{spacing(4)};

  @include from-breakpoint(sm) {
    --grid-spacing: #{spacing(8)} #{spacing(4)};
  }

  @include from-breakpoint(lg) {
    --grid-spacing: #{spacing(12)} #{spacing(6)};
  }
}

.u-grid--companies-grid {
  --grid-spacing: 0;

  @include from-breakpoint(sm) {
    --grid-spacing: #{spacing(4)} #{spacing(6)};
  }

  @include from-breakpoint(lg) {
    --grid-spacing: #{spacing(10)} #{spacing(18)};
  }
}

.u-grid--vacancy-card-grid {
  --grid-spacing: #{spacing(1)};
}

.u-grid--gap-0 {
  --grid-spacing: 0;
}
