/*  =========================================================================
    resets
    A thin layer on top of normalize.css that provides a starting point more
    suitable for web applications.
    ========================================================================= */

/**
 * 1. Set the global `box-sizing` state to `border-box`. As per:
 *    paulirish.com/2012/box-sizing-border-box-ftw
 * 2. Always add the vertical scrollbar to prevent layout shifting
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  box-sizing: border-box; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  scroll-behavior: smooth;

  &.lenis {
    scroll-behavior: auto;
    height: auto;

    /* stylelint-disable-next-line selector-class-pattern */
    &.lenis-smooth {
      scroll-behavior: auto;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.lenis-smooth [data-lenis-prevent] {
      overscroll-behavior: contain;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.lenis-stopped {
      overflow: hidden;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: inherit; /* [1] */
}

/**
 * Remove default margins from elements
 */
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
optgroup,
select {
  margin: 0;
}

/**
 * Removes unwanted margins & applies vertical rhythm to designated elements
 * As per: http://csswizardry.com/2012/06/single-direction-margin-declarations
 */
hgroup,
ul,
ol,
dl,
blockquote,
p,
address,
table,
fieldset,
figure,
pre,
hr {
  margin: 0 0 spacing(4);
}

/**
 * Default indentation value for lists
 */
ul,
ol {
  padding-left: spacing(4);
}

dd {
  margin-left: spacing(4);
}

/**
 * Strip unwanted paddings & styling from elements
 */
button {
  background: transparent;
}

button,
fieldset {
  padding: 0;
}

button,
iframe,
fieldset {
  border: 0;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  line-height: 1.15; /* 1 */
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
}

/**
 * Suppress the focus outline on links that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */
[tabindex='-1']:focus {
  outline: none !important;
}

/**
 * Gets rid of the annoying outline for mouse users but preserves it for
 * keyboard users, and is ignored by browsers that don’t support :focus-visible.
 * As per: https://twitter.com/LeaVerou/status/1045768279753666562
 */
:focus:not(:focus-visible) {
  // stylelint-disable-line selector-pseudo-class-no-unknown
  outline: none;
}

/**
 * Image
 *
 * 1. By default all images are fluid
 * 2. Render `alt` visually offset when images don't load
 */
img {
  max-width: 100%; /* [1] */
  font-style: italic; /* [2] */
}
