@import 'app.scss';
.wrapper {
  --horizontal-padding: 1.5rem;
  --icon-size: 1.5rem;
  --chevron-size: 1.25rem;
  --color: var(--color-fg-on-primary-brand);

  position: relative;
  width: 100%;
  max-width: 16rem;
}

.select {
  @include typography-body;

  appearance: none;
  border: solid 1px var(--color);
  border-radius: var(--border-radius-button);
  background-color: transparent;
  cursor: pointer;
  padding: spacing(4) var(--horizontal-padding);
  width: 100%;
  line-height: 1;
  color: var(--color);

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: var(--color) dotted 1px;
    outline-offset: 2px;
  }

  &:hover,
  &:focus-visible {
    background-color: var(--color-bg-primary-brand-hover);
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: var(--horizontal-padding);
  transform: translateY(-50%);
  transition: var(--transition-base);
  width: var(--chevron-size);
  height: var(--chevron-size);
  color: var(--color);
  pointer-events: none;
}
