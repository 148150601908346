@import 'app.scss';
.header {
  position: relative;
  z-index: 3;

  // Target the headroom element
  &:not(.menuIsShown) > * {
    @include from-breakpoint(lg) {
      height: calc(var(--header-height) * 1.2);
    }
  }
}

.backdrop {
  position: absolute;
  inset: 0;
  background: linear-gradient(#f5f4f2 25%, rgb(242 240 245 / 0.6) 100%);
  backdrop-filter: blur(4px);
  mask: linear-gradient(var(--color-black), var(--color-black), transparent);

  .menuIsShown & {
    display: none;
  }
}

.menuIsShown {
  position: fixed;
  background-color: var(--color-bg-primary-brand);
  width: 100%;
  height: 100dvh !important;
  overflow-y: scroll;

  > * {
    mask: none;
  }

  .logo {
    color: var(--color-logo-menu-open);

    &:hover,
    &:focus-visible {
      color: var(--color-logo-menu-open-hover);

      /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
      :global(#accent) {
        fill: var(--color-logo-accent-menu-open-hover);
      }

      /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
      :global(.poweredBy) {
        color: var(--color-logo-powered-by-menu-open-hover);
      }
    }

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.poweredBy) {
      color: var(--color-logo-powered-by-menu-open);
    }
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(#accent) {
      fill: var(--color-logo-accent-menu-open);
      transition: var(--transition-base);
    }
  }

  .close {
    display: block;
  }
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  column-gap: spacing(12);
  align-items: center;
  justify-content: space-between;
  padding-top: spacing(10);
  padding-bottom: spacing(12);

  @include from-breakpoint(lg) {
    padding-top: spacing(20);
    padding-bottom: spacing(10);
  }
}

.logoContainer {
  display: flex;
  position: relative;
  z-index: 2;

  @include from-breakpoint($menu-breakpoint) {
    flex: 1;
  }
}

.logo {
  color: var(--color-header-logo);

  &:hover,
  &:focus-visible {
    color: var(--color-header-logo-hover);
  }
}

.logoSvg {
  width: var(--header-logo-width);
  height: auto;

  // Neccessary to style the accent color in the nimbl logo
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global(#accent) {
    fill: var(--color-header-logo-accent);
  }
}

.menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transition: var(--transition-base);
  background-color: var(--color-bg-alternative);
  padding-top: spacing(20);
  width: 100%;
  height: 100%;
  color: var(--color-fg-on-alternative);

  &.menuIsShown {
    display: block;
  }

  @include from-breakpoint($menu-breakpoint) {
    display: block;
    position: static;
    background-color: transparent;
    padding: 0;
    width: auto;
  }
}

.menuContent {
  @include until-breakpoint($menu-breakpoint) {
    height: 100%;
    overflow-y: auto;
  }
}

.menuRight {
  display: none;

  @include from-breakpoint($menu-breakpoint) {
    display: flex;
    flex: 1 1 0%;
    justify-content: flex-end;
  }
}

.menuToggleContainer {
  display: flex;
  position: relative;
  gap: spacing(4);
  align-items: center;
  z-index: 1;
}

.close {
  display: none;
  margin-top: spacing(2);
  margin-bottom: 0;
  line-height: 100%;
  color: var(--color-fg-on-primary-brand);
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include from-breakpoint($menu-breakpoint) {
    gap: spacing(12);
  }
}
