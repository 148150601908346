@import 'app.scss';
@keyframes show-menu {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    transform: translateX(0);
    opacity: 0;
    width: 0;
  }

  50% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
    margin-right: spacing(2);
    width: spacing(12);
  }
}

@keyframes fade-out {
  0% {
    transform: translateX(0);
    opacity: 1;
    width: spacing(12);
  }

  90% {
    transform: translateX(0);
    opacity: 0;
    width: 0;
  }

  100% {
    width: 0;
  }
}

@keyframes fade-in-sub-icon {
  0% {
    transform: translateX(0);
    opacity: 0;
    width: 0;
  }

  50% {
    transform: translateX(3px);
    margin-left: spacing(-4);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
    margin-left: spacing(-4);
    width: spacing(12);
  }
}

@keyframes fade-out-sub-icon {
  0% {
    transform: translateX(0);
    opacity: 1;
    margin-left: spacing(-4);
    width: spacing(12);
  }

  90% {
    transform: translateX(0);
    opacity: 0;
    width: 0;
  }

  100% {
    width: 0;
  }
}

.menu {
  display: none;
  background-color: var(--color-bg-primary-brand);
  width: 100%;
  color: var(--color-fg-on-primary-brand);
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: spacing(16);
  margin-top: spacing(16);
  background-color: var(--color-bg-primary-brand);
  padding: 0;
  padding-bottom: var(--section-vertical-spacing);

  @include from-breakpoint(lg) {
    display: grid;
    margin-top: spacing(25);
  }
}

.primaryContainer,
.secondaryContainer {
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
  margin-bottom: 0;
}

.primaryContainer {
  gap: spacing(7);

  @include from-breakpoint(lg) {
    grid-column: 1 / span 7;
  }

  @include from-breakpoint(xl) {
    grid-column: 2 / span 6;
  }
}

.secondaryContainer {
  margin-top: spacing(4);

  @include from-breakpoint(lg) {
    margin-top: 0;
  }
}

.secondaryContainer,
.languageSwitchContainer {
  @include from-breakpoint(lg) {
    grid-column: 8 / span 4;
  }
}

.secondaryList {
  display: flex;
  flex-direction: column;
  gap: spacing(6);
  margin: 0;
}

.item,
.subItem {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
}

@include from-breakpoint(lg) {
  .item {
    &:hover,
    &:focus-visible {
      .icon {
        display: block;
        animation: fade-in 0.4s forwards;
      }
    }
  }

  .subItem {
    &:hover,
    &:focus-visible {
      .subIcon {
        display: block;
        animation: fade-in-sub-icon 0.4s forwards;
      }
    }
  }
}

.menuItem,
.secondaryItem {
  @include umbrella-link;

  transition: var(--transition-base);
  text-decoration: none;
  line-height: 125%;
  color: var(--color-fg-on-primary-brand);
  font-family: var(--font-family-heading);

  &:hover,
  &:focus-visible {
    color: var(--color-fg-on-primary-brand);
  }
}

.menuItem {
  line-height: 115%;
  font-size: clamp(2rem, 10vw, 3rem);
  font-weight: 600;
}

.secondaryItem {
  line-height: 160%;
  font-size: clamp(1rem, 5vw, 1.25rem);
}

.icon {
  opacity: 0;
  width: 0;
  height: spacing(8);
  animation: fade-out 0.2s forwards;

  @include from-breakpoint(md) {
    height: spacing(12);
  }
}

.subIcon {
  opacity: 0;
  width: 0;
  height: spacing(8);
  animation: fade-out-sub-icon 0.2s forwards;

  @include from-breakpoint(md) {
    height: spacing(7);
  }
}

.isOpen {
  display: block;
}

.languageSwitchContainer {
  display: flex;
  grid-column: 1/-1;
  align-items: center;
  margin-top: spacing(16);

  @include from-breakpoint(lg) {
    margin-top: spacing(14);
    width: spacing(62);
  }
}

.active {
  .menuItem,
  .secondaryItem {
    text-decoration: underline;
  }
}
