/*  =========================================================================
    Variables
    Global variables, config switches.
    You can NOT import this file other .scss files, instead it is imported in _app.js
    ========================================================================= */
:root,
::marker,
::after,
::before {
  // Brand colors
  --color-green-500: #66de80;
  --color-green-500-hover: #4fbb66; // Picked ourselfs
  --color-green-700: #003523;
  --color-green-700-hover: #002116;
  --color-green-800: #002116;
  --color-orange-500: #e8674a;
  --color-orange-500-hover: #dc4725;
  --color-purple-500: #502440;
  --color-purple-500-hover: #451634;
  --color-pink-500: #f1d0f4;
  --color-pink-500-hover: #d091d6;

  // Status colors
  --color-succes-100: hsl(143deg 50% 90%);
  --color-succes-500: hsl(143deg 100% 33%);
  --color-succes-900: hsl(143deg 100% 7%);
  --color-warning-100: hsl(45deg 87% 90%);
  --color-warning-500: hsl(45deg 87% 62%);
  --color-warning-900: hsl(45deg 100% 10%);
  --color-error-100: hsl(0deg 100% 95%);
  --color-error-200: hsl(0deg 100% 77%);
  --color-error-500: hsl(0deg 100% 57%);
  --color-error-700: hsl(0deg 100% 37%);
  --color-error-900: hsl(0deg 100% 10%);

  // Gray colors
  --color-white: #fff;
  --color-gray-100: #f5f4f2;
  --color-gray-200: #d3d3d3;
  --color-gray-300: #c4c4c4;
  --color-gray-500: #4c4c4c;
  --color-gray-900: #4f4f61;
  --color-black-transparent: #000000b3;
  --color-black: #0a1813;
  --color-black-hover: var(--color-gray-900);
  --color-black-rgb: 13, 32, 35;
  --color-text-base: var(--color-black);
  --color-border-base: hsl(0deg 0% 0% / 0.3);
  --color-border-light: rgb(79 79 97 / 0.2);

  // Image gradient colors
  --color-image-gradient-gray: #424242;
  --color-image-gradient-green: #003523;
  --image-gradient: linear-gradient(180deg, #003523 0%, #300e4b00 100%);

  // Transitions
  --transition-duration-base: 150ms;
  --transition-duration-long: 300ms;

  // Google swift bezier
  --transition-timing-base: cubic-bezier(0.55, 0, 0.1, 1);
  --transition-base: var(--transition-duration-base)
    var(--transition-timing-base);

  // Borders
  --border-radius-small: 0.25rem; // example: inputs
  --border-radius-base: 0.5rem; // example: cards
  --border-radius-medium: 1rem; // example: full width images
  --border-radius-large: 1.5rem; // example: SliderCard
  --border-radius-xl: 2rem; // example: company cards
  --border-radius-button: 1000px;

  // Shadows
  --box-shadow-base: 0 1.5rem 2rem rgb(0 0 0 / 0.05);
  --box-shadow-alternative: 1rem 1.5rem 7rem 1.5rem rgb(0 0 0 / 0.05);

  // defaults to big touch targets
  --target-base-height: 48px;
  --target-small-height: var(--target-base-height);
  --target-tiny-height: var(--target-base-height);

  // Spacings & container widths
  --max-container-width: 76.5rem;
  --max-text-width: 46rem;
  --section-vertical-spacing: #{spacing(8)};
  --grid-spacing: #{spacing(2)};
  --horizontal-padding: #{spacing(5)};

  // Header
  --header-height: #{spacing(40)};
  --header-logo-width: 7.8125rem;

  // Footer
  --footer-logo-width: 7.8125rem;

  @include from-breakpoint(xs) {
    --horizontal-padding: #{spacing(6)};
  }

  @include from-breakpoint(md) {
    --grid-spacing: #{spacing(4)};
    --horizontal-padding: #{spacing(15)};
    --section-vertical-spacing: #{spacing(12)};
  }

  @include from-breakpoint(lg) {
    --horizontal-padding: #{spacing(16)};
    --section-vertical-spacing: #{spacing(17)};
  }

  @include from-breakpoint(xl) {
    --grid-spacing: #{spacing(6)};
    --horizontal-padding: #{spacing(22)};
    --max-container-width: 88.5rem;
  }

  // slightly smaller buttons for certain devices
  @media (pointer: fine) {
    --target-base-height: 45px;
    --target-small-height: 40px;
    --target-tiny-height: 1.5rem;
  }
}
