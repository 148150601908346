/**
 * Typography styling
 * Shared base styles for (responsive) typography sizes
 */

@mixin typography-body {
  line-height: 160%;
  font-size: 1rem;
  font-weight: 400;
}

@mixin typography-paragraph-xs {
  line-height: 160%;
  font-size: 0.75rem;
  font-weight: 400;
}

@mixin typography-paragraph-xs-light {
  line-height: 160%;
  font-size: 0.75rem;
  font-weight: 300;
}

@mixin typography-paragraph-sm {
  line-height: 160%;
  font-size: 0.875rem;
  font-weight: 400;
}

@mixin typography-heading-card {
  line-height: 140%;
  font-family: var(--font-family-heading);
  font-size: 1rem;
  font-weight: 400;

  @include from-breakpoint(md) {
    font-size: 1.25rem;
  }
}

@mixin typography-heading-content-card {
  line-height: 115%;
  font-family: var(--font-family-heading);
  font-size: 1.25rem;
  font-weight: 700;
}

@mixin typography-heading-1 {
  line-height: 130%;
  font-family: var(--font-family-heading);
  font-size: 1.75rem;
  font-weight: 500;

  @include from-breakpoint(md) {
    font-size: 2.5rem;
  }
}

@mixin typography-heading-2 {
  line-height: 130%;
  font-family: var(--font-family-heading);
  font-size: 1.5rem;
  font-weight: 500;

  @include from-breakpoint(md) {
    font-size: 2rem;
  }
}

@mixin typography-heading-3 {
  line-height: 130%;
  font-family: var(--font-family-heading);
  font-size: 1.25rem;
  font-weight: 500;

  @include from-breakpoint(md) {
    font-size: 1.5rem;
  }
}

@mixin typography-heading-4 {
  line-height: 130%;
  font-family: var(--font-family-heading);
  font-size: 1rem;
  font-weight: 600;

  @include from-breakpoint(md) {
    font-weight: 700;
  }
}

@mixin typography-heading-5 {
  line-height: 130%;
  font-family: var(--font-family-heading);
  font-size: 1rem;
  font-weight: 600;

  @include from-breakpoint(md) {
    font-weight: 700;
  }
}

@mixin typography-heading-6 {
  line-height: 130%;
  font-family: var(--font-family-heading);
  font-size: 1rem;
  font-weight: 600;

  @include from-breakpoint(md) {
    font-weight: 700;
  }
}
