/*  =========================================================================
    Token
    Global token variables
    You can NOT import this file other .scss files, instead it is imported in _app.js
    ========================================================================= */
:root,
::marker,
::after,
::before {
  /* Background colors,
    use backgrounds to give elements a background color to provide contrast,
    to differentiate importancy or to convey meaning
  */
  --color-bg-default: var(--color-gray-100);
  --color-bg-alternative: var(--color-white);
  --color-bg-primary-brand: var(--color-green-500);
  --color-bg-primary-brand-hover: var(--color-green-500-hover);
  --color-bg-primary-cta: var(--color-orange-500);
  --color-bg-primary-cta-hover: var(--color-orange-500-hover);
  --color-bg-secondary-cta: var(--color-green-700);
  --color-bg-secondary-cta-hover: var(--color-green-700-hover);
  --color-bg-tertiary-cta: transparent;
  --color-bg-tertiary-cta-hover: transparent;
  --color-bg-quaternary-cta: var(--color-purple-500);
  --color-bg-quaternary-cta-hover: var(--color-purple-500-hover);
  --color-bg-quinary-cta: var(--color-pink-500);
  --color-bg-quinary-cta-hover: var(--color-pink-500-hover);
  --color-bg-senary-cta: var(--color-white);
  --color-bg-senary-cta-hover: var(--color-white);
  --color-bg-menu-toggle: var(--color-green-500);
  --color-bg-menu-toggle-hover: var(--color-green-500-hover);
  --color-bg-filter-cta: transparent;
  --color-bg-filter-cta-hover: var(--color-purple-500-hover);
  --color-bg-disabled-cta: var(--color-gray-200);
  --color-bg-contact-section-primary: var(--color-purple-500);
  --color-bg-contact-section-accent: var(--color-green-500);
  --color-bg-top-footer: var(--color-green-700);
  --color-bg-bottom-footer: var(--color-green-800);

  /* Foreground colors,
    Use foregrounds to color text and icons.
    Most foregrounds should be paired with their respective bg.{name} equivalent.
  */
  --color-fg-on-default: var(--color-gray-500);
  --color-fg-on-alternative: var(--color-green-700);
  --color-fg-on-primary-brand: var(--color-green-700);
  --color-fg-on-primary-brand-hover: var(--color-green-700-hover);
  --color-fg-on-primary-cta: var(--color-white);
  --color-fg-on-secondary-cta: var(--color-white);
  --color-fg-on-tertiary-cta: var(--color-green-700);
  --color-fg-on-quaternary-cta: var(--color-white);
  --color-fg-on-quinary-cta: var(--color-purple-500);
  --color-fg-on-senary-cta: var(--color-green-700);
  --color-fg-on-senary-cta-hover: var(--color-green-700-hover);
  --color-fg-on-disabled-cta: var(--color-white);
  --color-fg-on-text-cta: var(--color-green-700);
  --color-fg-on-text-cta-hover: var(--color-green-800);
  --color-fg-on-menu-toggle: var(--color-green-700);
  --color-fg-on-menu-toggle-hover: var(--color-green-700-hover);
  --color-fg-on-filter-cta: var(--color-purple-500);
  --color-fg-on-filter-cta-hover: var(--color-white);
  --color-fg-on-header-nav: var(--color-green-700);
  --color-fg-on-header-nav-hover: var(--color-green-700-hover);
  --color-fg-on-contact-section-primary: var(--color-white);
  --color-fg-on-contact-section-accent: var(--color-gray-500);
  --color-fg-on-top-footer: var(--color-white);
  --color-fg-on-top-footer-hover: var(--color-green-500);
  --color-fg-on-bottom-footer: var(--color-white);
  --color-fg-on-bottom-footer-hover: var(--color-green-500);

  /* Headers colors */
  --color-headings-fg-on-default: var(--color-green-700);

  /* Miscellaneous */
  --color-link-elements: var(--color-green-700);
  --color-header-logo: var(--color-green-700);
  --color-header-logo-hover: var(--color-green-700-hover);
  --color-logo-menu-open: var(--color-green-700);
  --color-logo-menu-open-hover: var(--color-green-800);
  --color-footer-logo: var(--color-green-500);
  --color-footer-logo-hover: var(--color-green-500-hover);
  --color-quote-text: var(--color-green-700);
  --color-quote-mark: var(--color-green-500);
}
