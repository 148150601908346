/* stylelint-disable selector-class-pattern */

// Margin bottom
.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-1 {
  margin-bottom: spacing(1);
}

.u-mb-2 {
  margin-bottom: spacing(2);
}

.u-mb-1\@from-sm {
  @include from-breakpoint(sm) {
    margin-bottom: spacing(1);
  }
}

.u-mb-4 {
  margin-bottom: spacing(4);
}

.u-mb-4\@from-md {
  @include from-breakpoint(md) {
    margin-bottom: spacing(4);
  }
}

.u-mb-6 {
  margin-bottom: spacing(6);
}

.u-mb-14\@from-lg {
  @include from-breakpoint(lg) {
    margin-bottom: spacing(14);
  }
}

// Margin top
.u-mt-10 {
  margin-top: spacing(10);
}

.u-mt-16\@from-md {
  @include from-breakpoint(md) {
    margin-top: spacing(16);
  }
}

.u-mt-vertical-spacing {
  margin-top: var(--section-vertical-spacing);
}
