/* stylelint-disable selector-class-pattern */

.u-typography-body {
  @include typography-body;
}

.u-typography-paragraph-xs {
  @include typography-paragraph-xs;
}

.u-typography-paragraph-xs-light {
  @include typography-paragraph-xs-light;
}

.u-typography-paragraph-sm {
  @include typography-paragraph-sm;
}

.u-typography-heading-card {
  @include typography-heading-card;
}

.u-typography-heading-content-card {
  @include typography-heading-content-card;
}

.u-typography-heading-1 {
  @include typography-heading-1;
}

.u-typography-heading-2 {
  @include typography-heading-2;
}

.u-typography-heading-3 {
  @include typography-heading-3;
}

.u-typography-heading-4 {
  @include typography-heading-4;
}

.u-typography-heading-5 {
  @include typography-heading-5;
}

.u-typography-heading-6 {
  @include typography-heading-6;
}

.u-white-space-pre-line {
  white-space: pre-line;
}
