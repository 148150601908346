/*  =========================================================================
    Elements
    Unclassed HTML element selectors
    ========================================================================= */
html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-rendering: geometricprecision;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
}

label,
button {
  cursor: pointer;
}

img {
  display: block;

  // Makes sure each lazyload 3px by 3px is as big as its container
  width: 100%;
  height: auto;
}

label,
legend {
  display: block;
  font-weight: bold;

  &[for] {
    cursor: pointer; /* [1] */
  }

  small {
    font-weight: normal;
  }
}

a {
  transition: color var(--transition-duration-base)
    var(--transition-timing-base);
  text-decoration: underline;
  text-underline-offset: 0.125rem;
  color: var(--color-link-elements);
  text-decoration-skip-ink: auto;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }

  &:focus-visible {
    @include generic-focus;
  }
}

hr {
  opacity: 0.3;
  border: none;
  background: linear-gradient(to right, #000, transparent);
  height: 1px;
}

svg {
  display: block;
}
