.nextFonts {
  font-family: var(--font-family-base);
}

body {
  @include typography-body;

  background-color: var(--color-bg-default);
  color: var(--color-fg-on-default);
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: spacing(10);
  color: var(--color-headings-fg-on-default);
}

h1 {
  @include typography-heading-1;
}

h2 {
  @include typography-heading-2;
}

h3 {
  @include typography-heading-3;
}

h4 {
  @include typography-heading-4;
}

h5 {
  @include typography-heading-5;
}

h6 {
  @include typography-heading-6;
}

::marker {
  color: var(--color-fg-interactive);
}
