@mixin button($variant: primary) {
  --border-color: var(--bg-color);

  /* prettier-ignore */
  --button-padding-vertical: #{spacing(4)};
  --button-padding-horizontal: #{spacing(6)};

  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition-duration: var(--transition-duration-base);
  transition-property: border, color, background-color, box-shadow;
  transition-timing-function: var(--transition-duration-timing);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-button);
  background-color: var(--bg-color);
  padding: var(--button-padding-vertical) var(--button-padding-horizontal);
  min-height: var(--height);
  vertical-align: middle;
  text-decoration: none;
  color: var(--color);
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: var(--bg-color-hover);
    color: var(--color-hover);

    .iconContainer {
      transform: translateX(3px);
    }
  }

  &[disabled],
  [disabled] > & {
    cursor: not-allowed;
  }

  &[disabled] {
    border-color: var(--color-bg-disabled-cta) !important;
    background-color: var(--color-bg-disabled-cta) !important;
    color: var(--color-fg-on-disabled-cta) !important;
  }

  @include from-breakpoint(md) {
    --button-padding-horizontal: #{spacing(8)};
  }

  @if $variant == secondary {
    --color: var(--color-fg-on-secondary-cta);
    --color-hover: var(--color-fg-on-secondary-cta);
    --bg-color: var(--color-bg-secondary-cta);
    --bg-color-hover: var(--color-bg-secondary-cta-hover);
  } @else {
    --color: var(--color-fg-on-primary-cta);
    --color-hover: var(--color-fg-on-primary-cta);
    --bg-color: var(--color-bg-primary-cta);
    --bg-color-hover: var(--color-bg-primary-cta-hover);
  }
}
