@import 'app.scss';
.navbar {
  display: none;

  @include from-breakpoint($menu-breakpoint) {
    display: flex;
    column-gap: spacing(12);
    margin-bottom: 0;
  }
}

.listItem {
  line-height: 1;
}

.navbarItem {
  text-decoration: none;
  color: var(--color-fg-on-header-nav);

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    color: var(--color-fg-on-header-nav-hover);
  }
}

.active {
  text-decoration: underline;
}
