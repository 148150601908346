@import 'app.scss';
.menuToggle {
  display: flex;
  position: relative;
  gap: spacing(2);
  align-items: center;
  transition:
    color var(--transition-base),
    background-color var(--transition-base);
  z-index: 1;
  border: 1px solid var(--color-bg-menu-toggle);
  border-radius: 50%;
  background-color: var(--color-bg-menu-toggle);
  width: spacing(12);
  height: spacing(12);
  line-height: 1.25;
  font-family: var(--font-family-base);
  font-size: 1rem;
  font-weight: 500;
  pointer-events: all;

  &:hover,
  &:focus-visible {
    background-color: var(--color-bg-menu-toggle-hover);
  }

  &:focus-visible {
    @include generic-focus;
  }

  &[aria-expanded='true'] {
    border-color: var(--color-fg-on-menu-toggle);
    background-color: var(--color-bg-menu-toggle);

    &:hover,
    &:focus-visible {
      background-color: var(--color-bg-menu-toggle-hover);
    }

    .icon {
      span {
        color: var(--color-fg-on-menu-toggle);

        &:nth-child(1) {
          top: 50%;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          width: 0%;
        }

        &:nth-child(3) {
          top: 50%;
          left: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.icon {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 19px;
  height: spacing(3);

  span {
    position: absolute;
    transform: translateY(-50%);
    transition: all var(--transition-base);
    border-radius: var(--border-radius-base);
    background-color: currentcolor;
    height: 2px;
    color: var(--color-fg-on-menu-toggle);

    &:nth-child(1) {
      top: 0;
      left: 0;
      width: 100%;
    }

    &:nth-child(2) {
      top: 50%;
      left: 0;
      width: 100%;
    }

    &:nth-child(3) {
      top: 100%;
      left: 0;
      width: 100%;
    }
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
